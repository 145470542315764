<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="title-block text-center text-lg-start mb-3">
                <h1 class="mb-0"> All Deals in {{route.params.location}}</h1>
            </div>
        </div>
    </div>
    <div class="mobile-map-filter d-lg-none mt-md-0">
        <span class="filter-btn">
            <button type="button" class="btn sec-button" data-bs-toggle="modal" data-bs-target="#filterModal"><fa icon="sliders-h" /> Filter</button>
        </span>
    </div>
    <card-shimmer :is-loading="store.isLoading" :num-of-rows="3" :columns="shimmerColumns" />
    <div v-if="store.isLoading === false"> 
        <div class="row" v-if="store.deals.length > 0">
            <div class="col-md-3 col-sm-6" v-for="deal in store.deals" :key="deal.id">
                <div class="deals-block">
                    <div class="deals-list">
                        <div class="thumb">
                            <router-link :to="'/deal/' + deal.slug"><img v-if="deal.banner" :src="deal.banner" alt="" class="img-fluid" /><img v-else src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/deals/default.jpg" alt="" class="img-fluid" /></router-link>
                            <!-- <div class="mi-no">10 mi</div> -->
                            <div class="price-tag"><span class="regular-price">${{deal.total_price}}</span> ${{deal.discounted_price}}</div>
                        </div>
                        <div class="content">
                            <router-link :to="'/deal/' + deal.slug"><h3 class="title">{{deal.title}}</h3></router-link>
                            <p>By {{deal.b_title}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="load-more" v-if="store.load_more.value">
            <button class="btn btn-primary" type="button" :disabled="store.load_more.isLoading" v-on:click="loadMore"> 
                <span v-if="store.load_more.isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span v-if="store.load_more.isLoading"> Loading...</span>
                <span v-if="!store.load_more.isLoading"> Load More</span>
            </button>
        </div>
        <div class="row" v-if="store.deals.length == 0">
            <div class="col-12 text-center">
                <img src="@/assets/images/deals/no-deals.svg" style="width: 150px">
                <h4 class="fw-normal">No Deals</h4>
                <p>No deals found near you</p>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
    import { watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { useDealsStore } from '@/stores/deals'
    import CardShimmer from '@/components/shimmer/CardShimmer';
	const store = useDealsStore()
    const route = useRoute()

    store.getDeals(route.params)

    const shimmerColumns = [{width:'92%',height:'18px'},{width:'50%',height:'12px'},{width:'38%',height:'12px'}];

    const loadMore = () => {
        store.page++
        store.load_more.isLoading = true
        store.getDeals(route.params,true)
    }

    watch(() => store.filters_, (filters) => {
        store.page = 1
        store.updateFilters(route,filters)
    },{ deep: true });
</script>